<template>
    <v-dialog
        v-model="dialog.show"
        transition="dialog-bottom-transition"
        fullscreen
        persistent
    >
        <template v-slot:activator="{ on, attrs }">
            <v-btn
                fab
                fixed
                bottom
                right
                class="primary"
                style="margin-bottom: 20% !important"
                v-on="on"
                :loading="loading"
                :disabled="loading"
                v-bind="attrs"
            >
                <v-icon>mdi-plus</v-icon>
            </v-btn>
        </template>
        <v-card :disabled="loading" :loading="loading">
            <!-- in ios add pt-3 and height='80' -->
            <v-toolbar dark color="primary" class="rounded-0"
            :class="$ios_toolbar_class"
            :height="$ios_toolbar_height"
            >
                <v-btn icon dark @click="dialog.show = false">
                    <v-icon>mdi-close</v-icon>
                </v-btn>
                <v-toolbar-title>Add Employee</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <v-list three-line subheader>
                <v-list-item>
                    <v-list-item-content>
                        <v-list-item-title>Employee Details</v-list-item-title>
                        <v-list-item-subtitle
                            >Please make sure to fill all required
                            fields</v-list-item-subtitle
                        >
                    </v-list-item-content>
                </v-list-item>
            </v-list>
            <v-divider></v-divider>
            <v-list three-line subheader>
                <v-list-item>
                    <v-container class="py-4">
                        <v-form v-model="valid">
                            <v-row>
                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="name"
                                        label="Full Name"
                                        name="name"
                                        :rules="name_rules"
                                        required
                                        color="theme"
                                    />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="username"
                                        label="Username"
                                        name="username"
                                        :rules="username_rules"
                                        required
                                        color="theme"
                                    />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="phoneNumber"
                                        name="phone"
                                        :rules="phone_rules"
                                        label="Phone Number"
                                        color="theme"
                                    />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="emailAddress"
                                        name="email"
                                        :rules="email_rules"
                                        label="Email Address"
                                        color="theme"
                                    />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="password"
                                        name="password"
                                        type="password"
                                        :rules="password_rules"
                                        label="Password"
                                        color="theme"
                                    />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-text-field
                                        v-model="confirmPassword"
                                        type="password"
                                        :rules="confirm_password_rules"
                                        name="confirm-password"
                                        label="Confirm Password"
                                        color="theme"
                                    />
                                </v-col>

                                <v-col cols="12" md="6">
                                    <v-autocomplete
                                        v-model="role"
                                        :items="user_enabled_role"
                                        :item-value="(item) => item.value"
                                        :item-text="(item) => item.text"
                                        label="Role"
                                        :rules="role_rules"
                                        color="theme"
                                    />
                                </v-col>

                                <v-col
                                    cols="12"
                                    md="6"
                                    v-if="
                                        role == 'mr' ||
                                        role == 'mr_admin' ||
                                        role == 'sr_admin' ||
                                        role == 'sr'
                                    "
                                >
                                    <v-autocomplete
                                        clearable
                                        v-model="manufacturer_id_cmp"
                                        :item-text="
                                            (manufacturer) => manufacturer.name
                                        "
                                        :item-value="
                                            (manufacturer) =>
                                                manufacturer.manufacturer_id
                                        "
                                        :multiple="
                                            role == 'mr' || role == 'sr'
                                                ? true
                                                : false
                                        "
                                        :chips="
                                            role == 'mr' || role == 'sr'
                                                ? true
                                                : false
                                        "
                                        :items="manufacturers"
                                        label="Manufacturer"
                                        color="theme"
                                    />
                                </v-col>

                                <v-col cols="12" class="text-right">
                                    <v-btn
                                        color="primary"
                                        :loading="loading"
                                        :disabled="!valid || loading"
                                        text
                                        @click="submit"
                                        >Submit</v-btn
                                    >
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-container>
                </v-list-item>
            </v-list>
        </v-card>
    </v-dialog>
</template>
<script>
import Api from "@/api/api/index";
import rules from "@/util/rules/index.js";

export default {
    methods: {
        async submit() {
            this.loading = true;
            try {
                await Api.post("employee/register", {
                    name: this.name,
                    phone: this.phoneNumber,
                    manufacturer_id: this.manufacturer_id,
                    manufacturer_ids: this.manufacturer_ids,
                    email: this.emailAddress,
                    password: this.password,
                    username: this.username,
                    role: this.role,
                });

                const notification = {
                    msg: "User created",
                    color: "success",
                };
                await this.$store.dispatch("user/notify", notification);
                this.dialog.show = false;
                this.$emit("refresh");
            } catch (err) {
                let notification = {
                    msg:
                        err.response?.data?.error?.msg ||
                        "Error, please try again later",
                    color: "error",
                };
                await this.$store.dispatch("user/notify", notification);
            }
            this.loading = false;
        },
        clear() {
            this.name = "";
            this.username = "";
            this.password = "";
            this.confirmPassword = "";
            this.phoneNumber = "";
            this.emailAddress = "";
            this.role = "";
        },
    },
    watch: {
        show(val) {
            if (val === true) {
                this.clear();
            }
        },
    },
    data() {
        return {
            valid: false, 
            manufacturers: [],
            dialog: {
                show: false, 
            },
            loading: false,
            manufacturer_id: null,
            manufacturer_ids: null,
            name: "",
            username: "",
            phoneNumber: "",
            emailAddress: "",
            password: "",
            confirmPassword: "",
            role: "",
            roles: [
                {
                    text: "Sales-Rep",
                    value: "sr",
                },
                {
                    text: "Marketing-Rep",
                    value: "mr",
                },
                {
                    text: "Marketing-Reps Admin",
                    value: "mr_admin",
                },
                {
                    text: "Sales-Reps Admin",
                    value: "sr_admin",
                },
                {
                    text: "Admin",
                    value: "admin",
                },
            ],
        };
    },
    async mounted() {
        try {
            const manufacturers = (
                await Api.post("manufacturer/fetch/all", null, {
                    dontCache: true,
                })
            ).data.results;
            this.manufacturers.push(...manufacturers);
        } catch (err) {
            let notification = {
                msg:
                    err.response?.data?.error?.msg ||
                    "Error, please try again later",
                color: "error",
            };
            await this.$store.dispatch("user/notify", notification);
            console.log(err);
        }
    },
    computed: {
        role_rules(){
            const field = "Role"
            return [rules.required(field)]
        },
        name_rules() {
            const field = "Name";
            return [rules.required(field), rules.name(field)];
        },
        username_rules() {
            const field = "Username";
            return [rules.required(field), rules.username(field)];
        },
        email_rules() {
            const field = "Email";
            return [rules.email(field)];
        },
        phone_rules() {
            const field = "Phone";
            return [rules.phone(field)];
        },

        password_rules() {
            const field = "Password";
            return [rules.required(field), rules.password(field)];
        },
        confirm_password_rules() {
            const field = "Confirm-Password";
            return [
                rules.required(field),
                rules.password(field),
                (v) => {
                    return (
                        this.password === this.confirmPassword ||
                        "password does not match"
                    );
                },
            ];
        },

        user_role() {
            return this.$store.getters["user/role"];
        },

        show() {
            return this.dialog.show;
        },
        user_enabled_role() {
            if (this.user_role == "sr_admin") {
                return this.roles.filter((role) => role.value == "sr");
            } else if (this.user_role == "mr_admin") {
                return this.roles.filter((role) => role.value == "mr");
            } else if (this.user_role == "admin") {
                return this.roles;
            } else {
                return [];
            }
        },
        manufacturer_id_cmp: {
            set(v) {
                if (this.role == "mr" || this.role == "sr") {
                    this.manufacturer_ids = v;
                } else {
                    this.manufacturer_id = v;
                }
            },
            get() {
                if (this.role == "mr" || this.role == "sr") {
                    return this.manufacturer_ids;
                } else {
                    return this.manufacturer_id;
                }
            },
        },
    },
};
</script>

